<template>
  <div>
    <b-row>
      <b-col>
        <b-card>
          <validation-observer ref="simpleRules">
            <b-form class="ml-1 mr-1 mt-1">
              <b-row>
                <b-col sm="12">
                  <b-form-group
                    label="Contraseña anterior: "
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Contraseña anterior"
                      rules="required"
                    >
                      <b-form-input
                        v-model="userData.old_password"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
                <b-col sm="12">
                  <b-form-group
                    label="Nueva contraseña: "
                  >
                    <validation-provider
                      #default="{ errors }"
                      name="Nueva contraseña"
                      rules="required"
                    >
                      <b-form-input
                        v-model="userData.password"
                      />
                      <small class="text-danger">{{ errors[0] }}</small>
                    </validation-provider>
                  </b-form-group>
                </b-col>
              </b-row>
            </b-form>
          </validation-observer>
          <b-row class="text-center">
            <b-col
              md="12"
              class="mb-1"
            >
              <b-button
                v-ripple.400="'rgba(255, 255, 255, 0.15)'"
                variant="primary"
                class="mr-1"
                @click.prevent="validationForm"
              >
                <span class="align-middle">Actualizar</span>
              </b-button>
            </b-col>
          </b-row>
        </b-card>
      </b-col>
    </b-row>
  </div>
  <!-- modal -->
</template>

<script>
import store from '@/store/index'
import {
  BRow, BCol, BCard, BForm, BFormGroup, BButton, BFormInput,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'
import Ripple from 'vue-ripple-directive'
// eslint-disable-next-line no-unused-vars
import { required } from '@validations'

export default {
  components: {
    BRow,
    BCol,
    BCard,
    BForm,
    BFormGroup,
    ValidationProvider,
    ValidationObserver,
    BButton,
    BFormInput,
  },
  directives: {
    Ripple,
  },
  data() {
    return {
      userData: {
        old_password: '',
        password: '',
        id: JSON.parse(localStorage.getItem('userData')).id,
      },
    }
  },
  mounted() {
  },
  methods: {
    async Guardar() {
      const request = {
        url: '/api/users/updatePassword',
        method: 'POST',
        headers: {
          Authorization: `Bearer ${localStorage.getItem('accessToken')}`,
        },
        data: this.userData,
      }
      try {
        const respRoles = await store.dispatch('back/EXECUTE', request)
        if (respRoles.status) {
          this.sendMessage(respRoles.message, 'AlertCircleIcon', 'danger')
        } else {
          this.sendMessage('Contraseña actualizada', 'EditIcon', 'success')
          this.$router.push({ name: 'facturador' })
        }
      } catch (e) {
        console.log(e.message)
      }
    },
    validationForm() {
      this.$refs.simpleRules.validate().then(success => {
        if (success) {
          this.Guardar()
        }
      })
    },
    sendMessage(title, icon, variant) {
      this.$toast({
        component: ToastificationContent,
        props: {
          title,
          icon,
          variant,
        },
      })
    },
  },
}
</script>

<style lang="scss">

</style>
